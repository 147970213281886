
::v-deep ul,
li {
    padding: 0;
    margin: 0;
    list-style: none;
}
.artificial-paper {
    display: flex;
    flex-direction: column;
    padding: 30px 0;
    height: 100%;
    .main {
        flex: 1;
        display: flex;
        height: 100%;
        overflow: hidden;
        .left-set-score {
            width: 350px;
            height: 93%;
        }
        .right-question {
            flex: 1;
            display: flex;
            flex-direction: column;
            // overflow: scroll;
            overflow: hidden;
            height: 100%;
        }
        .scroll-content {
            height: 100%;
            .paging-box {
                padding-bottom: 20px;
                margin-bottom: 20px;
                text-align: center;
            }
        }
    }
}
// ::v-deep .el-scrollbar__view {
//     height: 100%;
// }
