
.artificial-paper-list {
    flex: 1;
    padding: 0 30px;
    font-size: 14px;
    color: #333;
    .question-list {
        .footer {
            min-height: 60px;
            background-color: #f2f3fa;
            .result-box {
                box-sizing: border-box;
                padding: 10px;
            }
            .result {
                color: #1ab163;
            }
        }
        .icon {
            margin-right: 5px;
            color: #eb3800;
            font-size: 27px;
            font-weight: 700;
            cursor: pointer;
        }
    }
}
::v-deep .el-button {
    padding: 10px 7px;
}
::v-deep .paper-operate .add {
    background-color: #1122d8;
    border-color: #1122d8;
}
::v-deep .paper-operate .remove {
    background-color: #e84932;
    border-color: #e84932;
}
::v-deep .paper-operate .el-button {
    color: #fff;
    border-radius: 2px;
}
