
.question-list {
    .question-item {
        position: relative;
        margin-bottom: 40px;
        .question-name,
        .options,
        .footer {
            padding-left: 30px;
            // padding-right: 30px;
            padding-right: 10px;
        }
        border: 1px solid #d2d2d2;
        .question-name {
            display: flex;
            margin: 26px 0;
            .modify {
                white-space: nowrap;
            }
        }
        .options {
            margin-bottom: 22px;
            .item {
                margin-bottom: 30px;
            }
            .item:last-child {
                margin-bottom: 0;
            }
        }
        .draggable-icon {
            position: absolute;
            right: 8px;
            top: 8px;
            font-size: 30px;
            color: #304156;
        }
    }
}
