
.artificial-paper-operate {
    padding: 7px 30px;
    margin-bottom: 20px;
    background-color: #f2f3fa;
    color: #666;
    .left-search {
        width: 350px;
    }
    .right-operate {
        .item {
            margin-left: 40px;
        }
        .total-question-count .count {
            color: #e84932;
        }
        .delete-question {
            position: relative;
            color: #e84932;
            font-size: 35px;
            .icon {
                cursor: pointer;
            }
            .corner-mark {
                position: absolute;
                right: 0;
                top: 0;
                height: 14px;
                padding: 0 7px;
                color: #fff;
                font-size: 10px;
                line-height: 14px;
                background-color: #e84932;
                border-radius: 8px;
                transform: translate(60%);
                border: 1px solid #fff;
            }
            .delete-box {
                position: absolute;
                top: 120%;
                left: 80%;
                width: 221px;
                padding: 0;
                color: #333;
                font-size: 14px;
                background-color: #fff;
                transform: translate(-100%);
                border: 1px solid #dcdcdc;
                z-index: 9;
                .head {
                    padding: 6px 14px;
                    background-color: #f2f3fa;
                }
                .content {
                    padding: 0 14px;
                    border-top: 1px solid #dcdcdc;
                    border-bottom: 1px solid #dcdcdc;
                    .total-question-count {
                        padding: 10px 0 16px 0;
                        color: #666;
                    }
                    .type-box {
                        .type-item {
                            display: flex;
                            align-items: center;
                            padding: 10px 0;
                            .type-name {
                                flex: 4;
                            }
                            .type-count {
                                flex: 6;
                            }
                            .icon {
                                color: #eb3800;
                                font-weight: 700;
                                font-size: 16px;
                            }
                        }
                        .type-item:nth-child(1) {
                            padding-top: 0;
                        }
                    }
                }
                .footer {
                    padding: 12px 0;
                    text-align: center;
                    color: #999;
                    cursor: pointer;
                }
            }
        }
    }
}
//diglog
.clear-dialog {
    margin: 50px 0;
    .icon {
        font-size: 16px;
        color: #e84932;
    }
}

//else
::v-deep .el-input--mini .el-input__inner {
    height: 32px;
}
//复选框
::v-deep .el-checkbox__input.is-checked + .el-checkbox__label {
    color: #666;
}
::v-deep .el-checkbox__input.is-checked .el-checkbox__inner {
    background-color: #1122d8;
    border-color: #1122d8;
}

::v-deep .el-button {
    padding: 10px 7px;
}
//button
::v-deep .blue-button {
    background-color: #1122d8;
    border-color: #1122d8;
    color: #fff;
}
